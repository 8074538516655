import React from 'react';
import { themes } from '@esub-engineering/react-component-library';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Provider } from 'react-redux';
import { GraphQLClientProvider } from '@esub-engineering/common-containers';
import createStore from '../redux/store';
import Routes from '../Routes';
import useStyles from './styles';

const store = createStore();

// may expand options, and/or split clients at a later time
export const client = new QueryClient();

const App = () => {
  const classes = useStyles();
  return (
    <Provider store={store}>
      <QueryClientProvider client={client}>
        <GraphQLClientProvider>
          <ThemeProvider theme={themes.main}>
            <CssBaseline />
            <div className={classes.root}>
              <Routes />
            </div>
          </ThemeProvider>
        </GraphQLClientProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
